<div id="scrolltoverify">

</div>
<!------------------ Liste des médecins acceptés ----------------------->
<div class="informationPat3" *ngIf="showPart()==1">
  <div class="row bar">
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="callListMedecinsAccepted()"><a
        class="linkPart2"><b>Liste des médecins acceptés</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a
        class="linkPart"><b>Liste des médecins en attente</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart3()"><a
        class="linkPart"><b>Liste des médecins intégrés</b></a></div>
        <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart4()"><a
          class="linkPart"><b>API des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row">
      <div class="col">
        <input type="text" [(ngModel)]="searchNom" (input)="filter()" name="searchNom" class="inputRecherche"
          placeholder="Nom">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchPrenom" (input)="filter()" name="searchPrenom" class="inputRecherche"
          placeholder="Prénom">
      </div>
      <div class="col">
        <select class="form-control input-sm" id="specialite" onkeypress="return /[0-9]/.test(event.key)"
          [(ngModel)]="searchSpecialite" (change)="filter()" style="height: 60px;" name="searchSpecialite">
          <option disabled selected>Spécialite</option>
          <option>Acupuncture</option>
          <option>Allergologue</option>
          <option>Anatomo-Cyto-Pathologiste</option>
          <option>Anesthésiste-Réanimateur</option>
          <option>Angiologue</option>
          <option>Audioprothésiste</option>
          <option>Audioprothésiste</option>
          <option>Biochimiste</option>
          <option>Biochimiste Clinique</option>
          <option>Biologiste Medicale</option>
          <option>Biophysique</option>
          <option>Cancérologue</option>
          <option>Cardiologue</option>
          <option>Chirurgien</option>
          <option>Chirurgien Cancérologue</option>
          <option>Chirurgien Cardio-Vasculaire</option>
          <option>Chirurgien Cardio-Vasculaire Thoracique</option>
          <option>Chirurgien Esthétique</option>
          <option>Chirurgien Généraliste</option>
          <option>Chirurgien Maxillo Facial Stomatologue</option>
          <option>Chirurgien Orthopédiste</option>
          <option>Chirurgien Orthopédiste Traumatologue</option>
          <option>Chirurgien Pédiatrique</option>
          <option>Chirurgien Plasticien</option>
          <option>Chirurgien Thoracique</option>
          <option>Chirurgien Urologue</option>
          <option>Dentiste</option>
          <option>Dermatologue</option>
          <option>Diabétologue</option>
          <option>Diététicien</option>
          <option>Embryologiste</option>
          <option>Endocrinologue</option>
          <option>Endocrinologue Diabétologue</option>
          <option>Gastro-entérologue</option>
          <option>Gériatre</option>
          <option>Généraliste</option>
          <option>Gynécologue</option>
          <option>Gynécologue Obstétricien</option>
          <option>Hématologue</option>
          <option>Hématologue Clinique</option>
          <option>Hématopathologiste</option>
          <option>Hépatologue</option>
          <option>Hypnothérapeute</option>
          <option>Imagerie Médicale</option>
          <option>Immunologiste</option>
          <option>Immunopathologiste</option>
          <option>Interniste</option>
          <option>Interniste Maladies Infectieuses</option>
          <option>Interniste Réanimation Médicale</option>
          <option>Kinésithérapeute</option>
          <option>Maladies Infectieuses</option>
          <option>Médecin Biologiste</option>
          <option>Médecin du Travail</option>
          <option>Médecin Esthétique</option>
          <option>Médecin Légiste</option>
          <option>Médecin Nucléaire</option>
          <option>Médecin Physique</option>
          <option>Médecin Physique Réadaptateur</option>
          <option>Médecine Préventive</option>
          <option>Microbiologiste</option>
          <option>Néonatologiste</option>
          <option>Néphrologue</option>
          <option>Neurochirurgien</option>
          <option>Neurologue</option>
          <option>Nutritionniste</option>
          <option>Oncologue-Radiothérapeute</option>
          <option>Ophtalmologiste</option>
          <option>Orthodontiste</option>
          <option>Orthopédiste</option>
          <option>Orthopédiste Traumatologue</option>
          <option>Orthophoniste</option>
          <option>Orthoptiste</option>
          <option>Ostéopathe</option>
          <option>Parasitologiste</option>
          <option>Pédiatre</option>
          <option>Pédodontiste</option>
          <option>Pédopsychiatre</option>
          <option>Pharmacien Biologiste</option>
          <option>Phlébologue</option>
          <option>Physiologiste</option>
          <option>Physiothérapeute</option>
          <option>Pneumologue</option>
          <option>Podologue</option>
          <option>Proctologue</option>
          <option>Proctologue</option>
          <option>Prothésiste dentaire</option>
          <option>Psychanalyste</option>
          <option>Psychiatre</option>
          <option>Psychologue</option>
          <option>Psychomotricien</option>
          <option>Psychothérapeute</option>
          <option>Radiologue</option>
          <option>Radiothérapeute</option>
          <option>Réanimateur Médical</option>
          <option>Rhumatologue</option>
          <option>Sexologue</option>
          <option>Stomatologue</option>
          <option>Urologue</option>
        </select>
      </div>
      <div class="col">
        <input type="text" onkeypress="return /[a-zA-Z0-9@._-]/.test(event.key)" [(ngModel)]="searchEmail"
          (input)="filter()" name="searchEmail" class="inputRecherche" placeholder="Email">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchInscription" (input)="filter()" name="searchInscription"
          class="inputRecherche" placeholder="N° inscription">
      </div>
      <div class="col">
        <a class="fa-refresh" (click)="resetFilters()" title="Réinitialiser"> <i class="fa fa-refresh"></i> </a>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/ajoutMedecin']" title="Ajouter un nouveau médecin">
          <img class="iconButt2 " src="assets/plus-4.png"></a>
      </div>
    </div>
  </div>
  <div>
    <!--<a class="param" data-toggle="modal" data-target="#myModal4">Paramètres du tableau</a>-->
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>médecin</b></th>
          <th class=" gauche text-uppercase"><b>spécialité</b></th>
          <th class=" gauche text-uppercase"><b>email</b></th>
          <th class=" gauche text-uppercase"><b>N° inscription</b></th>
          <th class=" gauche text-uppercase"><b>Date inscription</b></th>
          <th class=" gauche text-uppercase"><b>Dernière activité</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
          <tr class=" gauche" *ngFor="let medecin of medecins| paginate: { itemsPerPage: 25, currentPage: p,id:'api' }">
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]" style="text-transform: capitalize;">
              {{medecin.nom}} {{medecin.prenom}}</td>
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]" style="text-transform: capitalize;">
              {{medecin.specialite}}</td>
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]">{{medecin.email}} </td>
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]">{{medecin.numInscription}} </td>
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]"><span
                *ngIf="medecin.dateInscription">{{medecin.dateInscription | date:'dd/MM/yyyy'}}</span> </td>
            <td class=" gauche" [routerLink]="['/medecin/details',medecin._id]" style="text-transform: capitalize;">
              <span *ngIf="medecin.lastActivated">{{medecin.lastActivated | date:'dd/MM/yyyy'}}</span> </td>
            <td class="row gauche" style="border-bottom-style: none;">
              <div class="col-1" style="margin-right: 10px !important;">
                <a title="Afficher" [routerLink]="['/medecin/details',medecin._id]">
                  <img src="assets/voirDetails-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 10px !important;">
                <a [routerLink]="['/medecin/modifier',medecin._id]" title="Modifier">
                  <img src="assets/pencil-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 10px !important;">
                <a (click)="setMedecinTodelet(medecin)" data-toggle="modal" data-target="#myModalSuppMedAcc"
                  title="Supprimer"><img src="assets/delete-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 10px !important;">
                <a [routerLink]="['/gestionRolesMedecins',medecin._id]" title="Gérer les rôles"><img
                    src="assets/settings-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1">
                <a [routerLink]="['/patients/medecin',medecin._id]" title="Liste des patients"><img
                    src="assets/patient-4.png" class="iconParametres"></a>
              </div>
              <div class="col-1">
                <a [routerLink]="['/secretaires/medecin',medecin._id]" title="Liste des secrétaires"><img
                    src="assets/burger.png" class="iconParametres"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="medecins && medecins.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);p = $event" id="api"></pagination-controls>
    </div>
  </div>
</div>
<!------------------ Liste des médecins en attente ----------------------->
<div class="informationPat3" *ngIf="showPart()==2">
  <div class="row bar">
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a
        class="linkPart"><b>Liste des médecins acceptés</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des médecins en
          attente</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart3()"><a
        class="linkPart"><b>Liste des médecins intégrés</b></a></div>
        <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart4()"><a
          class="linkPart"><b>API des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row">
      <div class="col">
        <input type="text" [(ngModel)]="searchNom" (input)="filterEnattente()" name="searchNom"
          class="inputRecherche" placeholder="Nom">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchPrenom" (input)="filterEnattente()" name="searchPrenom"
          class="inputRecherche" placeholder="Prénom">
      </div>
      <div class="col">
        <select class="form-control input-sm" id="specialite" [(ngModel)]="searchSpecialite"
          (change)="filterEnattente()" style="height: 60px;" name="searchSpecialite">
          <option disabled selected>Spécialite</option>
          <option *ngFor="let specialite of specialites">{{specialite}}</option>

        </select>
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchEmail" (input)="filterEnattente()" name="searchEmail"
          class="inputRecherche" placeholder="Email">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchInscription" (input)="filterEnattente()" name="searchInscription"
          class="inputRecherche" placeholder="N° inscription">
      </div>
      <div class="col">
        <a class="fa-refresh" (click)="resetFiltersEnattente()" title="Réinitialiser"> <i class="fa fa-refresh"></i> </a>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a [routerLink]="['/ajoutMedecin']" title="Ajouter un nouveau médecin">
          <img class="iconButt2 " src="assets/plus-4.png"></a>
      </div>
    </div>
  </div>
  <div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>médecin</b></th>
          <th class=" gauche text-uppercase"><b>spécialité</b></th>
          <th class=" gauche text-uppercase"><b>email</b></th>
          <th class=" gauche text-uppercase"><b>N° inscription</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
          <tr class=" gauche"
            *ngFor="let medecin of medecinsNotAccepted|searchPatient: {nom: searchMedEnAttente, prenom:searchMedEnAttente,  specialite: searchMedEnAttente,email:searchMedEnAttente,numInscription:searchMedEnAttente,diplome:searchMedEnAttente,tel:searchMedEnAttente} | paginate: { itemsPerPage: 25, currentPage: pc, id:'apic'  }">
            <td [routerLink]="['/medecin/details',medecin._id]" style="text-transform: capitalize;">{{medecin.nom}}
              {{medecin.prenom}}</td>
            <td [routerLink]="['/medecin/details',medecin._id]" style="text-transform: capitalize;">
              {{medecin.specialite}}</td>
            <td [routerLink]="['/medecin/details',medecin._id]">{{medecin.email}}</td>
            <td [routerLink]="['/medecin/details',medecin._id]">{{medecin.numInscription}}</td>
            <td class="row gauche" style="border-bottom-style: none;">
              <div class="col-1" style="margin-right: 10px !important;">
                <a title="Afficher" [routerLink]="['/medecin/details',medecin._id]">
                  <img src="assets/voirDetails-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 20px !important;">
                <a (click)="setMedecinTodelet(medecin)" data-toggle="modal" data-target="#myModalAcceptMedecin"
                  title="Accepter">
                  <img src="assets/check.png" class="iconParametres"></a>
              </div>
              <div class="col-1">
                <a (click)="setMedecinTodelet(medecin)" data-toggle="modal" data-target="#myModalSuppMedEnAttente"
                  title="Supprimer"><img src="assets/delete-7.png" class="iconParametres"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="medecinsNotAccepted && medecinsNotAccepted.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);pc = $event" id="apic"></pagination-controls>
    </div>
  </div>
</div>
<!------------------ Liste des médecins intégrés ----------------------->
<div class="informationPat3" *ngIf="showPart()==3">
  <div class="row bar">
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart1()"><a
        class="linkPart"><b>Liste des médecins acceptés</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a
        class="linkPart"><b>Liste des médecins en attente</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des médecins
          intégrés</b></a></div>
          <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart4()"><a
            class="linkPart"><b>API des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row">
      <div class="col">
        <input type="text" [(ngModel)]="searchNom" (input)="getRechercheResult()" name="searchNom"
          class="inputRecherche" placeholder="Nom">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchPrenom" (input)="getRechercheResult()" name="searchPrenom"
          class="inputRecherche" placeholder="Prénom">
      </div>
      <div class="col">
        <select class="form-control input-sm" id="specialite" [(ngModel)]="searchSpecialite"
          (change)="getRechercheResult()" style="height: 60px;" name="searchSpecialite">
          <option disabled selected>Spécialite</option>
          <option *ngFor="let specialite of specialites">{{specialite}}</option>

        </select>
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchEmail" (input)="getRechercheResult()" name="searchEmail"
          class="inputRecherche" placeholder="Email">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchInscription" (input)="getRechercheResult()" name="searchInscription"
          class="inputRecherche" placeholder="N° inscription">
      </div>
      <div class="col">
        <a class="fa-refresh" (click)="resetFiltersIntegre()" title="Réinitialiser"> <i class="fa fa-refresh"></i> </a>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
      <div class=" buttoncadre3"><a title="Ajouter un nouveau médecin intégré" [routerLink]="['/ajoutMedecinIntegre']">
          <img class="iconButt2 " src="assets/plus-4.png"></a>
      </div>
    </div>
  </div>
  <div>
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>médecin</b></th>
          <th class=" gauche text-uppercase"><b>spécialité</b></th>
          <th class=" gauche text-uppercase"><b>Adresse</b></th>
          <th class=" gauche text-uppercase"><b>Date inscription</b></th>
          <th class=" gauche text-uppercase"><b>Notes</b></th>
          <th class="  gauche text-uppercase" style="width: 20%;margin-right: 26px"><b>PARAMÈTRES</b></th>
        </thead>
        <tbody>
          <tr class=" gauche"
            *ngFor="let medecin of medecinsScraped | paginate: { itemsPerPage: 100, currentPage: pd, id :'apii' }">
            <td class=" gauche" [routerLink]="['/medecin/integre/modifier',medecin._id]"
              style="text-transform: capitalize;">{{medecin.nom}} {{medecin.prenom}}</td>
            <td class=" gauche" [routerLink]="['/medecin/integre/modifier',medecin._id]"
              style="text-transform: capitalize;">{{medecin.specialite}}</td>
            <td class=" gauche" [routerLink]="['/medecin/integre/modifier',medecin._id]"><span
                *ngIf="medecin.adresse">{{medecin.adresse.substring(0, 1).toUpperCase()}}{{medecin.adresse.substring(1,
                medecin.adresse.length)}}</span> <span
                *ngIf="!medecin.adresse && medecin.adresseCabinet">{{medecin.adresseCabinet.adresse.substring(0,
                1).toUpperCase()}}{{medecin.adresseCabinet.adresse.substring(1,
                medecin.adresseCabinet.adresse.length)}}</span> </td>
            <td class=" gauche" [routerLink]="['/medecin/integre/modifier',medecin._id]"
              style="text-transform: capitalize;"><span *ngIf="medecin.dateInscription">{{medecin.dateInscription |
                date:'dd/MM/yyyy'}}</span></td>
            <td class=" gauche" [routerLink]="['/medecin/integre/modifier',medecin._id]"
              style="text-transform: capitalize;">{{medecin.notes}}</td>
            <td class="row gauche" style="border-bottom-style: none;">
              <div class="col-1" style="margin-right: 20px !important;">
                <a [routerLink]="['/medecin/integre/modifier',medecin._id]" title="Modifier">
                  <img src="assets/pencil-7.png" class="iconParametres"></a>
              </div>
              <div class="col-1" style="margin-right: 20px !important;">
                <a (click)="redirectionToaddMedecin(medecin._id)" title="Insérer ce médecin">
                  <img src="assets/reset.png" class="iconParametres" style="position: relative;left: -20px;"></a>
              </div>
              <div class="col-1">
                <a (click)="setMedecinTodelet(medecin)" data-toggle="modal" data-target="#myModalSuppMedIntegre"
                  title="Supprimer"><img src="assets/delete-7.png" class="iconParametres"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="medecinsScraped && medecinsScraped.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);pd = $event" id="apii"></pagination-controls>
    </div>
  </div>

  <br><br><br><br><br><br>
  <!-- end  liste des utilisateurs -->

  <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"></div>
</div>




<!------------------ Liste des médecins acceptés ----------------------->
<div class="informationPat3" *ngIf="showPart()==4">
  <div class="row bar">
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="callListMedecinsAccepted()"><a
        class="linkPart"><b>Liste des médecins acceptés</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart2()"><a
        class="linkPart"><b>Liste des médecins en attente</b></a></div>
    <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart3()"><a
        class="linkPart"><b>Liste des médecins intégrés</b></a></div>
        <div class="col-10 col-xl-3 col-lg-3 col-md-10 col-sm-10 bar-section" (click)="modifPart4()"><a
          class="linkPart2"><b>API des médecins</b></a></div>
  </div>
  <div class=" search4">
    <div class="row">
      <div class="col">
        <input type="text" [(ngModel)]="searchNom" (input)="filterApi()" name="searchNom" class="inputRecherche"
          placeholder="Nom">
      </div>
      <div class="col">
        <input type="text" [(ngModel)]="searchPrenom" (input)="filterApi()" name="searchPrenom" class="inputRecherche"
          placeholder="Prénom">
      </div>
      <div class="col">
        <select class="form-control input-sm" id="specialite" [(ngModel)]="searchSpecialite"
          (change)="filterApi()" style="height: 60px;" name="searchSpecialite">
          <option disabled selected>Spécialite</option>
          <option *ngFor="let specialite of specialites">{{specialite}}</option>

        </select>
      </div>
      <div class="col">
          <select class="form-control input-sm" id="searchInscription" [(ngModel)]="searchInscription"
          (change)="filterApi()" style="height: 60px;" name="searchInscription">
          <option disabled selected>Gouvernorat</option>
          <option *ngFor="let ville of villes">{{ville}}</option>

        </select>
      </div>
      <div class="col">
        <a class="fa-refresh" (click)="resetfilterApi()" title="Réinitialiser"> <i class="fa fa-refresh"></i> </a>
      </div>
    </div>
  </div>
  <div class="row rightButts">
    <div class="col-11 ">
      <div class=" buttoncadre0">
      </div>
    </div>
    <div class="col-1">
    </div>
  </div>
  <div>
    <!--<a class="param" data-toggle="modal" data-target="#myModal4">Paramètres du tableau</a>-->
  </div>
  <div class="div-tab2">
    <div class="row" style="overflow-x:auto;">
      <table class="table  ">
        <thead>
          <th id="firstThTable" class=" gauche text-uppercase"><b>médecin</b></th>
          <th class=" gauche text-uppercase"><b>spécialité</b></th>
          <th class=" gauche text-uppercase"><b>adresse</b></th>
          <th class=" gauche text-uppercase"><b>code postal</b></th>
          <th class=" gauche text-uppercase"><b>gouvernorat</b></th>
          <th class=" gauche text-uppercase"><b>localité</b></th>
          <th class=" gauche text-uppercase"><b>catégorie prestataire</b></th>
          <th class=" gauche text-uppercase"><b>APC</b></th>
          <th class="  gauche text-uppercase" style="width: 2%;margin-right: 26px"><b></b></th>
        </thead>
        <tbody>
          <tr class=" gauche" *ngFor="let medecin of medecinsApi| paginate: { itemsPerPage: 25, currentPage: p,id:'api' }">
            <td class=" gauche"  style="text-transform: capitalize;">
              {{medecin.NOM}} {{medecin.PRENOM}}</td>
            <td class=" gauche"  style="text-transform: capitalize;">
              {{medecin.SPECIALITE}}</td>
            <td class=" gauche" >{{medecin.ADRESSE}} </td>
            <td class=" gauche" >{{medecin.CODE_POSTAL}} </td>
            <td class=" gauche" >{{medecin.GOUVERNORAT}} </td>
            <td class=" gauche" >{{medecin.LOCALITE}} </td>
            <td class=" gauche" >{{medecin.CATEGORIE_PRESTATAIRE}} </td>
            <td class=" gauche" >{{medecin.APC}} </td>
            <td class="row gauche" style="border-bottom-style: none;">
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pagination2" *ngIf="medecinsApi && medecinsApi.length > 25">
    <div class="row">
      <pagination-controls (pageChange)="pageChanged($event);p = $event" id="api"></pagination-controls>
    </div>
  </div>
</div>



<!---------------------------- Pop up supprimer Medecin ---------->
<div class="modal fade" id="myModalSuppMedAcc" tabindex="-1" role="dialog" aria-labelledby="myModal6"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER Médecin
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE médecin?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="removeMedecin()" data-dismiss="modal" data-toggle="modal"
                      data-target="#deleted"><img src="assets/delete2.png" class="iconBoutton">Supprimer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png"
                        class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myModalSuppMedEnAttente" tabindex="-1" role="dialog" aria-labelledby="myModal6"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER Médecin
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE médecin?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="removeMedecinAttente()" data-dismiss="modal"
                      data-toggle="modal" data-target="#deleted"><img src="assets/delete2.png"
                        class="iconBoutton">Supprimer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png"
                        class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="myModalSuppMedIntegre" tabindex="-1" role="dialog" aria-labelledby="myModal6"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          SUPPRIMER Médecin
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS SUPPRIMER CE médecin?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="removeMedecinIntegre()" data-dismiss="modal"
                      data-toggle="modal" data-target="#deleted"><img src="assets/delete2.png"
                        class="iconBoutton">Supprimer </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png"
                        class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up accepter médecin ---------->
<div class="modal fade" id="myModalAcceptMedecin" tabindex="-1" role="dialog" aria-labelledby="myModal6"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          accepter médecin
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 6%;">
          <div class="col-1" style="   margin-right: 4%;"><img src="assets/pers.png" class="imgPop"></div>
          <div class="col-10">
            <h4 class="textContenu text-uppercase">VOULEZ-VOUS accepter CE médecin?</h4>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <div class=" col d-flex justify-content-center buttonsPopup">
            <div class="col-auto nbr-dossier ">
              <table>
                <tr>
                  <td class="tdButt1 ">
                    <button class="btn-valider" (click)="accepteMedecin()" data-dismiss="modal" data-toggle="modal"
                      data-target="#deleted"><img src="assets/like.png" class="iconBoutton">Accepter </button>
                  </td>
                  <td class="tdButt2 ">
                    <button class="btn-annule" data-dismiss="modal"><img src="assets/annuler.png"
                        class="iconBoutton">Annuler </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---------------------------- Pop up Paramètres du tableau Medecins ---------->
<div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="myModal4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut text-uppercase">
          Paramètres du tableau
        </h3>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-left: 21%;">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Spécialité
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label class="form-check-label" for="flexCheckChecked">
                Email
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" checked>
              <label class="form-check-label" for="flexCheckChecked2">
                Num d'inscription
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" checked>
              <label class="form-check-label" for="flexCheckChecked3">
                Diplôme
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>