import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackofficeRoutingModule } from './backoffice-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BackofficeComponent } from './backoffice.component';
import { ImageService } from 'src/app/services/image/image.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MedecinsComponent } from './medecins/medecins/medecins.component';
import { AjoutMedecinComponent } from './medecins/ajout-medecin/ajout-medecin.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import{SearchPipe} from './search/search.pipe';
import{SearchEvenementPipe} from './search/searchEvenement.pipe';
import{SearchSujetPipe} from './search/searchSujet.pipe';
import{SearchSujetEnAttentePipe} from './search/searchSujetEnAttente.pipe';
import{SearchAdminPipe} from './search/searchAdmin.pipe';
import{SearchMedecinPipe} from './search/searchMedecin.pipe';
import{SearchConseilPipe} from './search/searchConseil.pipe';
import{SearchPatientPipe} from './search/searchPatient.pipe';
import{SearchingMedicamentsPipe} from './search/searchMedicaments.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import {ControlMessagesComponent } from './messages/control-messages.component'
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { AjoutMedecinIntegreComponent } from './medecins/ajout-medecin-integre/ajout-medecin-integre.component';
import { UpdateMedecinComponent } from './medecins/update-medecin/update-medecin.component';
import { UpdateMedecinIntegreComponent } from './medecins/update-medecin-integre/update-medecin-integre.component';
import { PatientsComponent } from './patients/patients/patients.component';
import { UpdatePatientComponent } from './patients/update-patient/update-patient.component';
import { AjouterPatientsMedComponent } from './patients/ajouter-patients-med/ajouter-patients-med.component';
import { PatientService } from 'src/app/services/patient/patient.service';
import { AgendaComponent } from './agenda/agenda.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { RdvService } from 'src/app/services/rdv/rdv.service';
import { EventsComponent } from './events/events/events.component';
import { AjoutEvenementsComponent } from './events/ajout-evenements/ajout-evenements.component';
import { DetailsEvenementsComponent } from './events/details-evenements/details-evenements.component';
import { UpdateEvenementsComponent } from './events/update-evenements/update-evenements.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EvenementService } from 'src/app/services/evenement/evenement.service';
import { NgxLoadingModule } from 'ngx-loading';
import { AdminsComponent } from './admins/admins/admins.component';
import { GestionRolesAdminComponent } from './admins/gestion-roles-admin/gestion-roles-admin.component';
import { ConseilsComponent } from './conseils/conseils/conseils.component';
import { DetailsConseilComponent } from './conseils/details-conseil/details-conseil.component';
import { ConseilService } from 'src/app/services/conseil/conseil.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { MedicamentsComponent } from './medicaments/medicaments/medicaments.component';
import { DetailsMedicamentsComponent } from './medicaments/details-medicaments/details-medicaments.component';
import { MedicamentsService } from 'src/app/services/medicaments/medicaments.service';
import { ProfilComponent } from './profil/profil.component';
import { DetailsMedecinsComponent } from './medecins/details-medecins/details-medecins.component';
import { GestionRolesMedecinsComponent } from './medecins/gestion-roles-medecins/gestion-roles-medecins.component';
import { PatientsMedecinComponent } from './patients/patients-medecin/patients-medecin.component';
import { ArticlesComponent } from './articles/articles/articles.component';
import { AjoutArticleComponent } from './articles/ajout-article/ajout-article.component';
import { UpdateArticleComponent } from './articles/update-article/update-article.component';
import { SecretairesComponent } from './secretaires/secretaires/secretaires.component';
import { AjoutSecretaireComponent } from './secretaires/ajout-secretaire/ajout-secretaire.component';
import { SecretaireService } from 'src/app/services/secretaire/secretaire.service';
import { UpdateSecretaireComponent } from './secretaires/update-secretaire/update-secretaire.component';
import { HopitauxComponent } from './hopitaux/hopitaux.component';
import { SearchHopitalPipe } from './search/searchHopital.pipe';
import { HopitauxService } from 'src/app/services/hopitaux/hopitaux.service';


@NgModule({
  declarations: [SearchHopitalPipe,DashboardComponent, BackofficeComponent, MedecinsComponent, AjoutMedecinComponent,ControlMessagesComponent,
    SearchPipe,SearchConseilPipe,SearchingMedicamentsPipe,SearchPatientPipe,SearchEvenementPipe,SearchSujetPipe,SearchSujetEnAttentePipe,SearchAdminPipe,SearchMedecinPipe, AjoutMedecinIntegreComponent, UpdateMedecinComponent, UpdateMedecinIntegreComponent, PatientsComponent, UpdatePatientComponent, AjouterPatientsMedComponent, AgendaComponent, EventsComponent, AjoutEvenementsComponent, DetailsEvenementsComponent, UpdateEvenementsComponent, AdminsComponent, GestionRolesAdminComponent, ConseilsComponent, DetailsConseilComponent, MedicamentsComponent, DetailsMedicamentsComponent, ProfilComponent, DetailsMedecinsComponent, GestionRolesMedecinsComponent, PatientsMedecinComponent, ArticlesComponent, AjoutArticleComponent, UpdateArticleComponent, SecretairesComponent, AjoutSecretaireComponent, UpdateSecretaireComponent, HopitauxComponent],
  imports: [
    CommonModule,NgxLoadingModule.forRoot({backdropBackgroundColour: 'rgba(0, 0, 0, 0.26)',
    fullScreenBackdrop:true,
    backdropBorderRadius: '4px',
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff'}),
    BackofficeRoutingModule,HttpClientModule,FileUploadModule,
    FormsModule, ReactiveFormsModule,NgxPaginationModule,InfiniteScrollModule,InternationalPhoneNumberModule,FullCalendarModule
  ],
  providers:[HopitauxService,ArticleService,ImageService,NotificationService,MedecinService,PatientService,RdvService,EvenementService,ConseilService,MedicamentsService,SecretaireService]
})
export class BackofficeModule { }
