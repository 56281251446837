<!------------------ Liste des hopitaux     ----------------------->
<div class="informationPat3">
    <div class="row bar">
      <div class="col-10 col-xl-4 col-lg-4 col-md-10 col-sm-10 bar-section"><a class="linkPart2"><b>Liste des hôpitaux </b></a></div>
    </div>
    <div class=" search4">
      <div class="row cover-search-row2">
        <div class="col-lg-5 col-md-8 col-sm-10 col-xs-10" style="margin-right: 4%">
          <div class="row rowSearch2">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 name-col-10">
              <input type="text" [(ngModel)]="search" name="search" class="inputRecherche" placeholder="Rechercher">
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 name-col-1" style="">
              <img id="name_img" src="" alt="" style="height: 25px;   margin-top: 19px; margin-left: -8px">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row rightButts">
      <div class="col-11 ">
        <div class=" buttoncadre0">
        </div>
      </div>
      <div class="col-1">
      </div>
    </div>
    <div class="div-tab2">
      <div class="row" style="overflow-x:auto;">
        <table class="table  ">
          <thead>
            <th id="firstThTable" class=" gauche text-uppercase"><b>Nom </b></th>
            <th class=" gauche text-uppercase"><b>Ville</b></th>
            <th class=" gauche text-uppercase"><b>N° tel</b></th>
            <th class=" gauche text-uppercase"><b>N° fixe</b></th>
            <th class=" gauche text-uppercase"><b>Adresse</b></th>
          </thead>
          <tbody>
            <tr class=" gauche" *ngFor="let hopital of hopitaux|searchHopital : search.toString().toLowerCase()| paginate: { itemsPerPage: 25, currentPage: p }">
              <td  style="text-transform: capitalize;" class=" gauche">{{hopital.lib_hop}} </td>
              <td  style="text-transform: capitalize;" class=" gauche">{{hopital.lib_gouv}}</td>
              <td  class=" gauche">{{hopital.tel}}</td>
              <td  class=" gauche">{{hopital.fax}}</td>
              <td  class=" gauche">{{hopital.numero}} {{hopital.adresse}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row" *ngIf="hopitaux && hopitaux.length > 25">
          <div class="col col-xs-6">
            <pagination-controls (pageChange)="p = $event" #api></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>