import { Component, OnInit } from '@angular/core';
import { Location, } from '@angular/common';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { ValidateService } from 'src/app/services/validate/validate.service';
import { SecretaireService } from 'src/app/services/secretaire/secretaire.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { MustMatch } from '../../pays/musLatch';

@Component({
  selector: 'app-ajout-secretaire',
  templateUrl: './ajout-secretaire.component.html',
  styleUrls: ['./ajout-secretaire.component.css','../../medecins/ajout-medecin/ajout-medecin.component.scss']
})
export class AjoutSecretaireComponent implements OnInit {
  form: any;
  admin: any;
  errorNumeroInscription: any;
  successRegister: any;
  showerrorligne = ""
  
  constructor(private validateService: ValidateService, private secretaireService: SecretaireService, private adminService: AdminService, private authService: AuthService, location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile: any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.errorNumeroInscription = false;
    this.successRegister = false;
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      email: ['', [Validators.required, ValidationService.emailValidator, Validators.maxLength(50)]],
      numInscription: ['', [Validators.required, Validators.maxLength(25)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25),Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')]],
      confrimPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25),Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')]],
      gestionRdvs: [''],
      gestionPatient: [''],
      gestionAgenda: [''],
      gestionDocuments: [''],
      gestionCertificats: [''],
      gestionOrdonnaces: [''],
      gestionMedecins: [''],
      gestionEvenements: [''],
      gestionSalleAttente: [''],
    },{
      validator: MustMatch('password', 'confrimPassword')
    })
  }
  get f() {
    return this.form.controls;
  }

  // enregistrer un médecin
  onRegisterSecretaire() {
    if (!this.form.value.nom || this.form.value.nom.length < 3 || this.form.value.nom.length > 25 || !this.form.value.prenom || this.form.value.prenom.length < 3 || this.form.value.prenom.length > 25) {
      this.showerrorligne = "Merci de vérifier les champs 'Nom & Prénom' (minimum 3, maximum 25)"
      return false;
    }
    if (!this.form.value.email || this.form.value.email.length < 3 || this.form.value.email.length > 50) {
      this.showerrorligne = "Merci de vérifier le champ 'Email' (minimum 3, maximum 50)"
      return false;
    }
    if (this.form.value.email && this.form.value.email.length > 1 && !this.validateService.validateEmail(this.form.value.email)) {
      this.showerrorligne = "Merci de vérifier le champ 'Email'"
      return false;
    }
    if (!this.form.value.password || this.form.value.password.length < 6 || this.form.value.password.length > 25) {
      this.showerrorligne = "Merci de vérifier le champ 'Mot de passe' (minimum 6, maximum 25)"
      return false;
    }
    if (!this.form.value.confrimPassword || this.form.value.confrimPassword.length < 6 || this.form.value.confrimPassword.length > 25) {
      this.showerrorligne = "Merci de vérifier le champ 'Confirmation du mot de passe' (minimum 6, maximum 25)"
      return false;
    }
    if (this.form.value.confrimPassword != this.form.value.password ) {
      this.showerrorligne = "Les mots de passe ne correspondant pas."
      return false;
    }
    if (this.form.invalid) {
      return;
    }
    this.showerrorligne = ""
    this.form.value.role = "secretaire";
    let params = this.activatedRoute.snapshot.params;
    var medecinId = ""
    if (params && params.id) {
      medecinId = params.id
    }
    this.form.value.medecinId = medecinId;
    this.form.value.roles = {
      gestionRdvs:  this.form.value.gestionRdvs,
      gestionPatient:  this.form.value.gestionPatient,
      gestionAgenda:  this.form.value.gestionAgenda,
      gestionDocuments:  this.form.value.gestionDocuments,
      gestionCertificats:  this.form.value.gestionCertificats,
      gestionOrdonnaces:  this.form.value.gestionOrdonnaces,
      gestionMedecins:  this.form.value.gestionMedecins,
      gestionEvenements:  this.form.value.gestionEvenements,
      gestionSalleAttente:this.form.value.gestionSalleAttente
    }
    this.secretaireService.registerSecretaire(this.form.value).subscribe(data => {
      if(data && data["success"]){
        this.form.reset();
      this.errorNumeroInscription = false;
      this.successRegister = true;
      Swal.fire({
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">Le secrétaire a été AJOUTÉ avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
      );
      this.router.navigate(['/secretaires/medecin',medecinId]);
      }else{
        if(data && !data["success"]  && data["errors"] && data["errors"].length >0){
          Swal.fire({
            width: 500,
            background: '#e1e1e1',
            timer: 3000,
            html: '<div class="row">' +
              '<div class="col-3">' +
              '<img src="assets/cancel.png" style="height:60px; margin-left: 20px">' +
              '</div>' +
              '<div><h4 class="text-uppercase" style="margin-top: 23px; font-size:16px">'+data["errors"][0].msg +'</h4></div>' +
              '</div>',
            showConfirmButton: false
          }
          );
          return false;
        }
      }
      
    });
  }

}

