import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
 name: 'searchHopital'
})
export class SearchHopitalPipe implements PipeTransform {
  transform(value, args: string[]){
   if (!value) return value;
   if (value.filter((item) => item.lib_hop.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.lib_hop.toLowerCase().includes(args));}
     if (value.filter((item) => item.lib_gouv.toLowerCase().includes(args)) != '') {
       return value.filter((item) => item.lib_gouv.toLowerCase().includes(args));}
     if (value.filter((item) => item.adresse.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.adresse.toLowerCase().includes(args));}
     if (value.filter((item) => item.tel.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.tel.toLowerCase().includes(args));}
     if (value.filter((item) => item.fax.toLowerCase().includes(args)) != '') {
     return value.filter((item) => item.fax.toLowerCase().includes(args));}


 }}