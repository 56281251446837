import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { AdminService } from 'src/app/services/admin/admin.service';
import { SecretaireService } from 'src/app/services/secretaire/secretaire.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MedecinService } from 'src/app/services/medecin/medecin.service';

@Component({
  selector: 'app-secretaires',
  templateUrl: './secretaires.component.html',
  styleUrls: ['./secretaires.component.css','../../medecins/medecins/medecins.component.css']
})
export class SecretairesComponent implements OnInit {

  form: any;
  public search: string = '';
  loading = false;
  admin: any;
  secretaires: any[] = [];
  medecins: any[] = [];
  secretaireTodelete:any;
  pageNumber = 0
  timeout: any = null;
  p:any
  idMedecin:any;
  medecin:any;
  constructor(private medecinService:MedecinService,private http:HttpClient,private secretaireService: SecretaireService, private adminService: AdminService,  private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    let params = this.activatedRoute.snapshot.params;
    if(params && params.id){
      this.idMedecin = params.id
      this.medecinService.getMedecinById(params.id).subscribe(medecin => {
        this.medecin = medecin;
        if(this.medecin && this.medecin._id){
          this.idMedecin = this.medecin._id
        }
      },
        err => {
          return false;
        });
      this.secretaireService.getAllsecretairesByMedecin(params.id).subscribe((secretaires:any[]) => {
        this.secretaires = secretaires;
        this.loading = false;
      },
        err => {
          return false;
        });
    }

  }

  removeSecretaire(secretaire) {
    secretaire = this.secretaireTodelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      this.http.delete(_getURL(API_CONFIG.secretaires) + '/' + secretaire._id, { headers: headers }).subscribe((res: any) => {
        this.secretaires.splice(this.secretaires.indexOf(secretaire), 1);
        Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le secrétaire a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
      })
  }
  setVaribaleTodatele(secretaire){
    this.secretaireTodelete = secretaire
  }
  
  


 

  
}
