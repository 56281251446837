import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { AdminService } from 'src/app/services/admin/admin.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { sortTable } from '../../search/sortTable';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css','../../medecins/medecins/medecins.component.css']
})
export class PatientsComponent implements OnInit {

  form: any;
  public search: string = '';
  loading = false;
  admin: any;
  patients: any[] = [];
  medecins: any[] = [];
  medecinPatients: any[] = [];
  patientTodelete:any;
  lengthPatients = 0
  pageNumber = 0
  timeout: any = null;
  p:any
  constructor(private http:HttpClient,private medecinService: MedecinService, private adminService: AdminService,  private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router, private formBuilder: FormBuilder) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionMedecins) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });
  }
  ngOnInit() {
    let el = document.getElementById('scrolltoverify');
    el.scrollIntoView({ behavior: "smooth" });
    this.loading = true;
    this.medecinService.getAllPatientsInscritsByPage(this.pageNumber, 10).subscribe((patients:any[]) => {
      this.patients = patients;
      this.loading = false;
    },
      err => {
        console.log(err);
        return false;
      });

      this.medecinService.getAllPatientsInscritscount().subscribe((length:any) => {
        this.lengthPatients = length;
      },
        err => {
          return false;
        });
  }

  removePatient(patient) {
    patient = this.patientTodelete
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token_adminMedivisto'),
      'Content-Type': 'application/json'
    });
      this.http.delete(_getURL(API_CONFIG.patients) + '/' + patient._id, { headers: headers }).subscribe((res: any) => {
        this.patients.splice(this.patients.indexOf(patient), 1);
        Swal.fire( {
        width: 500,
        background: '#e1e1e1',
        timer: 3000,
        html: '<div class="row">' +
          '<div class="col-3">' +
          '<img src="assets/checked.png" style="height:60px; margin-left: 20px">' +
          '</div>' +
          '<div><h4 class="text-uppercase" style="margin-left: 30px; margin-top: 15px">Le patient a été supprimé avec succès</h4></div>' +
          '</div>',
        showConfirmButton: false
      }
    );
      })
  }
  sortTable(n) {
   sortTable(n)
  }
  setVaribaleTodatele(patient){
    this.patientTodelete = patient
  }
  onScroll(){
    setTimeout(() => {    //<<<---    using ()=> syntax
      this.addData();
    }, 1000);
  }
  addData() {
    //console.log(this.lengthmedecinsScraped)
    if (this.patients && this.lengthPatients === this.patients.length) {
      return false;
    }
    this.pageNumber = this.pageNumber + 1
    this.medecinService.getAllPatientsInscritsByPage(this.pageNumber, 10).subscribe((patients:any[]) => {
      if(patients){
        for(var j =0; j < patients.length;j++){
          this.patients.push(patients[j]);
        }
      }
    },
      err => {
        return false;
      });
  }

  // filter and search commandes

  valuechange(event){
    clearTimeout(this.timeout);
      var $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.getRechercheResult(event.target.value)
        }
      },1000);
  }

  getRechercheResult(event){
    if(event && event.length >0 && event.length <4){
      return false;
    }
    this.patients = [];
    while (this.patients.length) {
      this.patients.pop();
    }
    if(!event || event === ""){
      this.pageNumber = 0
      this.medecinService.getAllPatientsInscritsByPage(this.pageNumber, 10).subscribe((patients:any) => {
        if(patients){
          for(var j =0; j < patients.length;j++){
            this.patients.push(patients[j]);
          }
        }
      },
        err => {
          return false;
        });
      return false;
    }
    this.medecinService.getPateintsBypageAndRechercheWithLimite(event).subscribe((data: any[]) => {
      this.patients = data
        },
      err => {
        return false;
      });
      return false;
  }
}
