import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HopitauxService } from 'src/app/services/hopitaux/hopitaux.service';

@Component({
  selector: 'app-hopitaux',
  templateUrl: './hopitaux.component.html',
  styleUrls: ['../admins/admins/admins.component.css','../medecins/medecins/medecins.component.css']

})
export class HopitauxComponent implements OnInit {
  public search: any = '';
  hopitaux: any[] = [];
  p:any;
  admin:any;
  constructor(private authService: AuthService,private hopitauxService:HopitauxService,
    private router: Router) {
    this.authService.getProfileAdmin().subscribe((profile:any) => {
      this.admin = profile.admin;
      if (!this.admin.roles.gestionAccueil) {
        this.router.navigate(['/notFound']);
        return false;
      }
    },
      err => {
        console.log(err);
        return false;
      });

  }

  ngOnInit() {
    this.hopitauxService.getAllHopitaux().subscribe((hopitaux:any[]) => {
      this.hopitaux = hopitaux;

    },
      err => {
        return false;
      });
  }
  
  

}

